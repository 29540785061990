import React from "react";
import { PageLayout, LocationsSorted } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import "../style/locations.css";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness } = data;

    return (
      <WebsiteLayout>
        <PageLayout className={"locations-page"} hero={null}>
          <Grid stackable={true} columns={1}>
            <Grid.Column>
              <LocationsSorted
                locations={fishermanBusiness.locations.map((location) => {
                  const locationNameToOrderLink = {
                    310: "https://direct.chownow.com/order/36491/locations/55379",
                    377: "https://direct.chownow.com/order/36491/locations/55382",
                    378: "https://direct.chownow.com/order/36491/locations/55381",
                  };
                  const locationFacebook = {
                    310: "https://www.facebook.com/valkyriedoughnutsSTP/",
                    377: "https://www.facebook.com/Valkyriedoughnuts/",
                    378: "https://www.facebook.com/valkyriedoughnutspgh/",
                  };
                  const locationInstagram = {
                    310: "https://www.instagram.com/valkyriedoughnuts_stpete/",
                    377: "https://www.instagram.com/valkyriedoughnuts",
                    378: "https://www.instagram.com/valkyriedoughnutspgh/",
                  };
                  return {
                    ...location,
                    actions: [
                      {
                        url: locationNameToOrderLink[location._id],
                        label: "Order Online",
                      },
                      {
                        url: locationFacebook[location._id],
                        label: "Facebook",
                        icon: "facebook",
                      },
                      {
                        url: locationInstagram[location._id],
                        label: "Instagram",
                        icon: "instagram",
                      },
                    ],
                  };
                })}
                searchHeader={"Locations"}
                searchHeaderAs={"h1"}
                showIcons={false}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      locations {
        phoneNumber
        email
        street
        city
        state
        zipCode
        name
        hours {
          day
          open
          close
          label
        }
        _id
      }
    }
  }
`;
